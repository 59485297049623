import * as React from "react"

export const Pressable = React.forwardRef(function Pressable(
  { onClick, children, className },
  ref
) {
  const handleKeyUp = (e) => {
    if ([13, 32].includes(e.keyCode)) {
      onClick()
    }
  }

  return (
    <div
      role="button"
      tabIndex="0"
      onClick={onClick}
      onKeyUp={handleKeyUp}
      className={className}
      ref={ref}
    >
      {children}
    </div>
  )
})
