import PropTypes from "prop-types"
import { useBreakpoints } from "hooks/useBreakpoints"
import * as React from "react"
import { Menu, MenuList, MenuSeparator } from "components/Menu"
import { MenuItem } from "components/MenuItem"
import cx from "classnames"
import { Text } from "components/Text"
import { DynamicIcon } from "components/Icon/DynamicIcon"
import { AngleRight } from "components/Icon/AngleRight"

export function KnpMenu(props) {
  const { nav, trigger: triggerProps } = props
  const { lg } = useBreakpoints()

  const trigger = React.cloneElement(triggerProps, {
    href: nav.children.length === 0 ? nav.uri : undefined,
    target: nav.children.length === 0 ? nav.attributes?.target : undefined,
  })

  if (lg) {
    return <KnpMenuDesktop nav={nav} trigger={trigger} />
  }

  return <KnpMenuMobile nav={nav} trigger={trigger} />
}

KnpMenu.propTypes = {
  nav: PropTypes.object,
  trigger: PropTypes.node,
}

function KnpMenuDesktop({ nav, trigger, placement }) {
  return (
    <div className="relative">
      <Menu
        trigger={trigger}
        list={<RecursiveMenuList nav={nav} placement={placement} />}
      />
    </div>
  )
}

KnpMenuDesktop.propTypes = { nav: PropTypes.object, trigger: PropTypes.node }

const RecursiveMenuList = ({ nav, isOpen, referenceElement, placement }) => {
  return (
    <MenuList
      isOpen={isOpen}
      referenceElement={referenceElement}
      placement={placement}
    >
      {nav.children.map((child) => (
        <Item key={child.name} item={child} />
      ))}
    </MenuList>
  )
}

function KnpMenuMobile(props) {
  const { nav, trigger } = props
  const [openItems, setOpenItems] = React.useState([])
  const [referenceElement, setReferenceElement] = React.useState(null)

  function openMenu() {
    setOpenItems([nav])
  }

  function openChildren(item) {
    setOpenItems((items) => [...items, item])
  }

  const itemToShow = openItems[openItems.length - 1]

  return (
    <div>
      {React.cloneElement(trigger, {
        ref: setReferenceElement,
        onClick: nav.children.length > 0 ? openMenu : undefined,
      })}
      {itemToShow ? (
        <MenuList
          referenceElement={referenceElement}
          title={itemToShow.name !== "root" ? itemToShow.name : null}
          isOpen={true}
          onRequestClose={() =>
            setOpenItems((items) => items.filter((item) => item !== itemToShow))
          }
        >
          {itemToShow.children.map((item) => {
            return (
              <Item
                key={item.name}
                item={item}
                onClick={
                  item.children.length > 0
                    ? (e) => {
                        e.preventDefault()
                        openChildren(item)
                      }
                    : undefined
                }
              />
            )
          })}
        </MenuList>
      ) : null}
    </div>
  )
}

KnpMenuMobile.propTypes = {
  nav: PropTypes.object,
  trigger: PropTypes.node,
}

const ItemLabel = ({ item }) => {
  return (
    <div className="flex flex-row space-x-2 items-baseline">
      <div
        className={cx("whitespace-nowrap", {
          "text-default": !item.extras.color,
          "text-error-default": item.extras.color === "danger",
          "text-primary-default": item.extras.color === "primary",
        })}
      >
        {item.name}
      </div>
      {item.extras.caption ? (
        <Text variant="tag" className="text-grey-medium">
          ({item.extras.caption})
        </Text>
      ) : null}
    </div>
  )
}

const ItemIcon = ({ item }) => {
  if (item.extras.icon === null) {
    return null
  }

  return (
    <DynamicIcon
      className={cx(
        {
          "text-error-default": item.extras.color === "danger",
          "text-primary-default": item.extras.color !== "danger",
        },
        "w-4"
      )}
      name={item.extras.icon}
    />
  )
}
const ItemEndAdornment = ({ item }) => {
  if (!item.extras.icon) {
    return null
  }

  return (
    <div className="flex flex-row space-x-2 items-center">
      {item.extras.counter !== null ? (
        <div className="w-6 h-6 flex text-white bg-error-default justify-center rounded-3xl">
          {item.extras.counter}
        </div>
      ) : null}
      {item.children.length > 0 ? <AngleRight className="w-2" /> : null}
    </div>
  )
}

const Item = ({ item, onClick }) => {
  if (item.extras.type === "delimiter") {
    return <MenuSeparator />
  }

  return (
    <div className="relative">
      <MenuItem
        onClick={onClick}
        key={item.name}
        href={item.children.length > 0 ? undefined : item.uri}
        label={<ItemLabel item={item} />}
        startAdornment={<ItemIcon item={item} />}
        endAdornment={<ItemEndAdornment item={item} />}
        selected={item.isCurrent}
        attributes={item.attributes}
      />
    </div>
  )
}
