/* eslint-env node */

module.exports = {
  content: ["./assets/js/**/*.js", "./templates/**/*.html.twig"],
  theme: {
    fill: (theme) => ({
      ...theme("colors"),
    }),
    stroke: (theme) => ({
      ...theme("colors"),
    }),
    // textShadow: (theme) => ({
    //   ...theme("boxShadow"),
    // }),
    extend: {
      textColor: {
        dark: "#111827",
        default: "#495161",
        light: "#888B93",
      },
      fontFamily: {
        sans: ["Ubuntu", "sans-serif"],
      },
      borderRadius: {
        "4xl": "3rem",
      },
      keyframes: (theme) => ({
        "shrink-pulse": {
          "0%, 100%": {
            color: theme("colors.primary.default"),
          },
          "50%": {
            color: theme("colors.primary.light"),
            transform: "scale(0.66)",
          },
        },
      }),
      animation: {
        "shrink-pulse": "shrink-pulse 600ms ease-in-out infinite",
      },
      spacing: { 18: "4.25rem", 128: "32rem" },
      minHeight: { 7: "1.75rem", 12: "3rem" },
      minWidth: { 5: "1.25rem" },
      height: { "screen-2/3": "66.66667vh", "screen-1/2": "50vh" },
    },
    colors: {
      primary: {
        darker: "var(--color-primary-darker)",
        dark: "var(--color-primary-dark)",
        default: "var(--color-primary-default)",
        light: "var(--color-primary-light)",
        lighter: "var(--color-primary-lighter)",
        contrast: "var(--color-primary-contrast)",
      },
      secondary: {
        darker: "var(--color-secondary-darker)",
        dark: "var(--color-secondary-dark)",
        default: "var(--color-secondary-default)",
        light: "var(--color-secondary-light)",
        lighter: "var(--color-secondary-lighter)",
        contrast: "var(--color-secondary-contrast)",
      },
      grey: {
        medium: "#BCC1CC",
        light: "#E1E4E9",
        lighter: "#F5F6F8",
        white: "#FFFFFF",
      },
      success: {
        default: "#4E8B43",
        light: "#C0DEBA",
        lighter: "#F2FFEF",
      },
      error: {
        default: "#AE0000",
        light: "#EBADAD",
        lighter: "#FFE7E7",
      },
      white: "white",
      black: "black",
      pink: "#FF69B4",
      transparent: "transparent",
      current: "currentColor",
    },
    fontSize: {
      "3xs": ["12px", "16px"],
      "2xs": ["14px", "24px"],
      xs: ["16px", "24px"],
      sm: ["18px", "24px"],
      base: ["20px", "24px"],
      lg: ["24px", "32px"],
      xl: ["32px", "40px"],
      "2xl": ["40px", "48px"],
    },
    boxShadow: {
      1: "0px 4px 4px rgba(0, 0, 0, 0.1)",
      2: "0px 4px 4px rgba(0, 0, 0, 0.3)",
      3: "0px 8px 8px rgba(0, 0, 0, 0.2)",
      modalMobile: "0px -4px 4px rgba(0, 0, 0, 0.1)",
    },
  },
  plugins: [
    require("tailwindcss-textshadow"),
    require("@tailwindcss/typography")
  ],
}
