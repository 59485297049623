import PropTypes from "prop-types"
import cx from "classnames"
import { Text } from "components/Text"
import { Times } from "components/Icon/Times"
import { Button } from "components/Button"
import * as React from "react"
import { Dialog } from "@headlessui/react"

export function Modal({ title, children, onRequestClose, isOpen }) {
  return (
    <Dialog
      open={isOpen}
      onClose={onRequestClose}
      className={cx("fixed inset-0 z-30 flex", {
        "opacity-0 invisible": !isOpen,
      })}
    >
      <Dialog.Overlay className="fixed inset-0 bg-black/50" />
      <div
        className={cx(
          "mt-auto",
          "md:m-auto",
          "z-20",
          "relative",
          "bg-white",
          "rounded-t-lg",
          "md:rounded-lg",
          "flex",
          "flex-col",
          "h-auto",
          "max-h-[95dvh]",
          "min-h-0",
          "bg-white",
          "w-full",
          "md:max-w-xl",
          "lg:max-w-2xl",
          "lg:w-auto",
          "shadow-modalMobile",
          "lg:shadow-3"
        )}
      >
        <Wrapper condition={true}>
          <div className="justify-between flex flex-row shrink-0 grow-0 pt-4 items-center space-x-4">
            <div className="grow">
              <ModalTitle>{title}</ModalTitle>
            </div>
            <div className="shrink-0">
              <Button
                type="button"
                variant="text"
                color="secondary"
                onClick={onRequestClose}
                iconLeft={<Times className="w-4" />}
              />
            </div>
          </div>
        </Wrapper>
        {children}
      </div>
    </Dialog>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node,
  onRequestClose: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
  id: PropTypes.string,
}

const ModalTitle = ({ children }) => {
  return (
    <Text className="text-primary-dark break-normal" variant="headline3">
      {children}
    </Text>
  )
}

export const ModalContent = React.forwardRef(function ModalContent(
  { children, wrapped = true },
  ref
) {
  return (
    <div className="grow min-h-0 overflow-y-auto py-5" ref={ref}>
      <Wrapper condition={wrapped}>{children}</Wrapper>
    </div>
  )
})

export const ModalActions = ({ children, wrapped = true }) => {
  return (
    <div className="py-2 bg-white shrink-0 md:rounded-b-xl">
      <Wrapper condition={wrapped}>
        <div className="flex justify-end space-x-2">{children}</div>
      </Wrapper>
    </div>
  )
}

const Wrapper = ({ children, condition }) => {
  return <div className={cx({ "px-6": condition })}>{children}</div>
}
