import * as React from "react"
import cx from "classnames"
import { Text } from "components/Text"
import PropTypes from "prop-types"

export const Section = ({ children, theme }) => {
  return (
    <SectionContext.Provider value={{ theme }}>
      <section
        className={cx("py-5 lg:py-8", {
          "bg-primary-darker text-white": theme === "primary",
          "bg-white": theme === "light",
        })}
      >
        {children}
      </section>
    </SectionContext.Provider>
  )
}

Section.propTypes = {
  theme: PropTypes.oneOf(["default", "light", "primary"]),
}

Section.defaultProps = {
  theme: "default",
}

const SectionContext = React.createContext()

const useSection = () => {
  const context = React.useContext(SectionContext)

  if (!context) {
    throw new Error(
      "`useSection` should be used in a `SectionContext.Provider`"
    )
  }

  return context
}

const SectionTitle = ({ children, size }) => {
  const { theme } = useSection()

  const variant = getTitleVariant(size)

  return (
    <Text
      variant={variant}
      className={cx({
        "text-white": theme === "primary",
        "mb-4": size === "small",
        "mb-6": size === "medium",
      })}
      tag="h2"
    >
      {children}
    </Text>
  )
}

SectionTitle.propTypes = {
  size: PropTypes.oneOf(["small", "medium"]),
}

SectionTitle.defaultProps = {
  size: "medium",
}

Section.Title = SectionTitle

const getTitleVariant = (size) => {
  const sizeToVariant = {
    medium: "headline3",
    small: "headline4",
  }

  const variant = sizeToVariant[size]

  if (!variant) {
    throw new Error(`Unknown \`Section.Title\` size: ${size}`)
  }

  return variant
}
