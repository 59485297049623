import * as React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Text } from "components/Text"
import { RawLink, hrefPropType } from "components/Link"
import { Pressable } from "components/Pressable"

export const MenuItem = React.forwardRef(function MenuItem(props, ref) {
  const {
    className,
    startAdornment,
    endAdornment,
    label,
    caption,
    disabled,
    selected,
    onSelect,
    href,
    focused,
    spacing,
    onClick,
    attributes,
    ...rest
  } = props

  const Tag = href ? RawLink : onSelect ? Pressable : "div"

  const handleSelect = (e) => {
    onSelect(e)
  }

  return (
    <Tag
      href={href}
      target={attributes?.target}
      ref={ref}
      className={cx(
        "w-full",
        "text-left",
        "flex",
        "flex-row",
        "space-x-2",
        "items-center",
        "text-default",
        {
          "bg-grey-light text-light cursor-default": disabled,
          "hover:bg-grey-lighter focus:bg-grey-lighter": !disabled,
          "bg-grey-lighter": (selected || focused) && !disabled,
          "py-3 px-4": spacing === "medium",
        },
        className
      )}
      {...rest}
      onClick={onSelect ? handleSelect : onClick}
    >
      {startAdornment}
      <div className={cx(className, "grow", "flex", "flex-col", "min-w-0")}>
        <Text variant="body2" className={cx({ "font-bold": selected })}>
          {label}
        </Text>
        {caption ? (
          <Text variant="tag" className="text-light">
            {caption}
          </Text>
        ) : null}
      </div>
      {endAdornment}
    </Tag>
  )
})

MenuItem.propTypes = {
  className: PropTypes.string,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  label: PropTypes.node.isRequired,
  caption: PropTypes.node,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  href: hrefPropType,
  focused: PropTypes.bool,
  onSelect: PropTypes.func,
  spacing: PropTypes.oneOf(["none", "medium"]),
}

MenuItem.defaultProps = {
  disabled: false,
  selected: false,
  focused: false,
  spacing: "medium",
}
