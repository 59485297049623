import { RouterContext } from "components/Router/RouterContext"
import * as router from "components/Router/router"
import PropTypes from "prop-types"

export const RouterProvider = ({ children }) => {
  return (
    <RouterContext.Provider value={router}>{children}</RouterContext.Provider>
  )
}

RouterProvider.propTypes = {
  children: PropTypes.node,
}
