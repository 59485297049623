import * as React from "react"
import { useRouter } from "components/Router"
import { Alerter } from "components/Alert"
import * as ContactType from "enums/ContactType"

const ConnectedUserContext = React.createContext(null)

export const ConnectedUserProvider = ({ user: userProps, children }) => {
  const router = useRouter()

  const addContact = async (userId, type = ContactType.NEW) => {
    const url = router.generate("app_user_contact_add", { userId, type })

    const response = await fetch(url, {
      method: "post",
      headers: { "X-Requested-With": "XMLHttpRequest" },
    })

    const data = await response.json()

    if (response.ok) {
      Alerter.success(data.message)

      setUser((user) => ({
        ...user,
        contactDetails: [
          ...user.contactDetails,
          {
            user_id: data.data.contact.id,
            contact_id: data.data.id,
            type: data.data.type.enum_value,
          },
        ],
      }))
    } else {
      Alerter.error(data.message)
    }

    return data
  }

  const deleteContact = async (contactId) => {
    const url = router.generate("app_user_contact_delete", { contactId })

    const response = await fetch(url, {
      method: "post",
      headers: { "X-Requested-With": "XMLHttpRequest" },
    })

    const data = await response.json()

    if (response.ok) {
      Alerter.success(data.message)

      setUser((user) => ({
        ...user,
        contactDetails: user.contactDetails.filter(
          (contact) => contact.contact_id !== contactId
        ),
      }))
      if (data.redirect_url) {
        setTimeout(() => {
          router.redirect(data.redirect_url)
        }, 2000)
      }
    } else {
      Alerter.error(data.message)
    }

    return data
  }

  const [user, setUser] = React.useState(userProps)

  return (
    <ConnectedUserContext.Provider
      value={user ? { ...user, addContact, deleteContact } : null}
    >
      {children}
    </ConnectedUserContext.Provider>
  )
}

export const useConnectedUser = () => {
  const user = React.useContext(ConnectedUserContext)
  return user
}
