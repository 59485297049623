import cx from "classnames"
import PropTypes from "prop-types"
import * as React from "react"

export function Avatar(props) {
  const { src, alt, size, className, fallback, ...rest } = props

  if (!src && !fallback) {
    return null
  }

  const classNames = cx(
    className,
    {
      "w-8 h-8": size === "xsmall",
      "w-12 h-12": size == "small",
      "w-20 h-20": size == "medium",
      "w-40 h-40": size == "large",
    },
    "rounded-full overflow-hidden"
  )
  return (
    <div className={classNames}>
      {src ? (
        <img
          src={src}
          alt={alt ? alt : ""}
          loading={"lazy"}
          {...rest}
          className="w-full h-full object-cover object-center"
        />
      ) : fallback ? (
        React.cloneElement(fallback, {
          className: (fallback.props.className || "") + " w-full",
        })
      ) : null}
    </div>
  )
}

Avatar.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(["xsmall", "small", "medium", "large"]),
  alt: PropTypes.string,
}
