import * as React from "react"
import PropTypes from "prop-types"
import useClickOutside from "use-onclickoutside"
import { useBreakpoints } from "hooks/useBreakpoints"

export function Menu({ trigger, list }) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [referenceElement, setReferenceElement] = React.useState(null)
  const { lg } = useBreakpoints()

  const toggleOpen = () => {
    setIsOpen((isOpen) => !isOpen)
  }

  const close = () => {
    setIsOpen(false)
  }

  const clickAwayRef = React.useRef(null)
  useClickOutside(clickAwayRef, close)

  return (
    <div ref={lg ? clickAwayRef : null}>
      {React.cloneElement(trigger, {
        onClick: toggleOpen,
        ref: setReferenceElement,
      })}
      {React.cloneElement(list, {
        onRequestClose: close,
        isOpen,
        referenceElement,
      })}
    </div>
  )
}

Menu.propTypes = {
  children: PropTypes.node,
}
