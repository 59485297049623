import cx from "classnames"
import PropTypes from "prop-types"
import * as React from "react"

export const Text = React.forwardRef(function Text(props, ref) {
  const { tag: Tag, variant, children, className, ...rest } = props

  const classNames = cx(
    {
      "text-xl font-bold lg:text-2xl": variant === "headline1",
      "text-lg font-bold lg:text-xl": variant === "headline2",
      "text-base font-bold lg:text-lg": variant === "headline3",
      "text-sm font-bold lg:text-base": variant === "headline4",
      "text-xs font-bold lg:text-sm": variant === "headline5",
      "text-3xs lg:text-xs font-bold": variant === "headline6",
      "text-2xs lg:text-xs font-medium uppercase tracking-wider":
        variant === "subtitle1",
      "text-3xs lg:text-xs uppercase tracking-wider": variant === "subtitle2",
      "text-xs font-medium": variant === "button",
      "text-xs lg:text-base": variant === "body1",
      "text-2xs lg:text-sm": variant === "body2",
      "text-3xs lg:text-xs": variant === "tag",
      "text-3xs lg:text-2xs": variant === "caption",
    },
    className
  )

  return (
    <Tag className={classNames} {...rest} ref={ref}>
      {React.Children.map(children, (child) => {
        if (typeof child !== "string") {
          return child
        }

        return child.split("\n").map((chunk, index, arr) => (
          <React.Fragment key={index}>
            {chunk}
            {index !== arr.length - 1 ? <br /> : null}
          </React.Fragment>
        ))
      })}
    </Tag>
  )
})

Text.defaultProps = {
  tag: "div",
  variant: "body1",
}

Text.propTypes = {
  tag: PropTypes.elementType,
  variant: PropTypes.oneOf([
    "headline1",
    "headline2",
    "headline3",
    "headline4",
    "headline5",
    "headline6",
    "subtitle1",
    "subtitle2",
    "button",
    "body1",
    "body2",
    "caption",
    "tag",
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
}
