import * as React from "react"
import { RouterContext } from "components/Router/RouterContext"

export const useRouter = () => {
  const router = React.useContext(RouterContext)

  if (!router) {
    throw new Error("useRouter should be used in a RouterProvider")
  }

  return router
}
