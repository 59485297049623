import { TranslatorContext } from "components/Translator/TranslatorContext"
import Translator from "bazinga-translator"
import * as React from "react"
import { useAsync } from "@react-hook/async"

export const TranslatorProvider = ({ locale, children }) => {
  const [translations, loadTranslations] = useAsync(async (locale) => {
    const localeModule = await import(
      `components/Translator/translations/${locale}.json`
    )

    Translator.reset()
    Translator.fromJSON(localeModule.default)
  })

  React.useEffect(() => {
    loadTranslations(locale)
  }, [loadTranslations, locale])

  if (["idle", "loading"].includes(translations.status)) {
    return null
  }

  if (translations.status === "error") {
    return (
      <div>
        Error while loading translations: {translations.error.toString()}
      </div>
    )
  }

  if (translations.status === "cancelled") {
    return <div>Translations loading cancelled</div>
  }

  return (
    <TranslatorContext.Provider value={Translator}>
      {children}
    </TranslatorContext.Provider>
  )
}
