import * as React from "react"
import { Alert } from "components/Alert"
import { Button } from "components/Button"
import { useTranslator } from "components/Translator"

const createStore = () => {
  let listeners = []

  const dispatch = (action) => {
    listeners.forEach((listener) => listener(action))
  }

  const subscribe = (listener) => {
    listeners.push(listener)

    return () => {
      listeners = listeners.filter((l) => l !== listener)
    }
  }

  return { dispatch, subscribe }
}

const store = createStore()

export const Alerter = () => {
  const [notifications, setNotifications] = React.useState([])

  const onAction = (action) => {
    switch (action.type) {
      case "notification":
        return setNotifications((notifications) => [
          ...notifications,
          {
            id: notifications.length,
            ...action.payload,
          },
        ])

      case "reset":
        return setNotifications([])
    }
  }

  const close = () => {
    setNotifications([])
  }

  React.useEffect(() => {
    const unsubscribe = store.subscribe(onAction)

    return unsubscribe
  }, [])

  const translator = useTranslator()

  return (
    <div
      className={
        "fixed max-w-xl w-full space-y-4 pb-4 top-0 lg:top-auto lg:bottom-0 z-40"
      }
    >
      {notifications.map((notification) => (
        <Alert
          severity={notification.severity}
          key={notification.id}
          message={notification.message}
          endAdornment={
            <div className="flex space-x-2">
              {notification.options?.actions?.map((action) => {
                return (
                  <Button
                    key={action.label}
                    href={action.href}
                    color="primary"
                    variant="text"
                  >
                    {action.label}
                  </Button>
                )
              })}
              <Button
                color="primary"
                variant="text"
                type="button"
                onClick={() => close()}
              >
                {translator.trans("Alerter.close", null, "components")}
              </Button>
            </div>
          }
        />
      ))}
    </div>
  )
}

Alerter.notice = (message, options) => {
  store.dispatch({
    type: "notification",
    payload: {
      severity: "notice",
      message,
      options,
    },
  })
}

Alerter.success = (message, options) => {
  store.dispatch({
    type: "notification",
    payload: {
      severity: "success",
      message,
      options,
    },
  })
}

Alerter.error = (message, options) => {
  store.dispatch({
    type: "notification",
    payload: {
      severity: "error",
      message,
      options,
    },
  })
}

Alerter.warning = (message, options) => {
  store.dispatch({
    type: "notification",
    payload: {
      severity: "warning",
      message,
      options,
    },
  })
}

Alerter.reset = () => {
  store.dispatch({ type: "reset" })
}
