import Routing from "fos-router"
import routes from "components/Router/routes.json"

Routing.setRoutingData(routes)
Routing.setLocale(document.documentElement || "fr")

export const generate = Routing.generate.bind(Routing)

/**
 * @param {RouteSpecs|string} specs
 */
export const redirect = (specs) => {
  if (typeof specs === "string") {
    return window.location.assign(specs)
  }

  const route = generate(specs.name, specs.params)

  window.location.assign(route)
}

/**
 * @typedef {Object} RouteSpecs
 * @property {string} name
 * @property {Object} [params]
 */
