import MicroEE from "microee"
import * as React from "react"

const prefix = "flag__"

class FlagStore {
  constructor() {
    this.getAllKeys().forEach((key) => this.emit("change", key))
  }

  get(name) {
    const value = window.localStorage.getItem(this.getKey(name))
    const parsed = value ? JSON.parse(value) : value
    return parsed
  }

  getAllKeys() {
    return Object.keys(localStorage).filter((key) => key.startsWith(prefix))
  }

  getKey(name) {
    return `${prefix}${name}`
  }

  set(name, value) {
    const strValue = JSON.stringify(value)
    window.localStorage.setItem(this.getKey(name), strValue)

    this.emit("change", name)
  }
}

MicroEE.mixin(FlagStore)

const store = new FlagStore()

export const enable = (flagName) => {
  store.set(flagName, true)
}

export const disable = (flagName) => {
  store.set(flagName, false)
}

export const isEnabled = (flagName) => {
  return store.get(flagName)
}

export const list = () => {
  const keys = store.getAllKeys().map((key) => key.replace(prefix, ""))
  const flags = keys.reduce((flags, key) => {
    flags[key] = store.get(key)
    return flags
  }, {})

  console.log(flags)
}

export const useFlag = (name) => {
  const [enabled, setEnabled] = React.useState(isEnabled(name))

  React.useEffect(() => {
    const handleChange = (changed) => {
      if (changed === name) {
        setEnabled(isEnabled(name))
      }
    }

    store.on("change", handleChange)

    return () => {
      store.removeListener("change", handleChange)
    }
  }, [name])

  return enabled
}
