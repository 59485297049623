import { Section } from "components/Section"
import { Wrapper } from "components/Wrapper"
import { useTranslator } from "components/Translator"
import logoTF1 from "components/PressSection/logos/tf1.png"
import logoFrance2 from "components/PressSection/logos/france2.png"
import logoFrance3 from "components/PressSection/logos/france3.png"
import logoFrance5 from "components/PressSection/logos/france5.png"
import logoFigaro from "components/PressSection/logos/figaro.png"
import logoSudOuest from "components/PressSection/logos/sudouest.png"
import logoVoilesEtVoiliers from "components/PressSection/logos/voilesetvoiliers.png"
import logoYahoo from "components/PressSection/logos/yahoo.png"

export const PressSection = () => {
  const translator = useTranslator()

  return (
    <Section theme="light">
      <Wrapper>
        <Section.Title size="small">
          {translator.trans("PressSection.title", null, "components")}
        </Section.Title>
        <div className="grid grid-cols-3 gap-10 place-items-center md:grid-cols-4 md:gap-20">
          <Logo src={logoTF1} alt="TF1" />
          <Logo src={logoFrance2} alt="France 2" />
          <Logo src={logoFrance3} alt="France 3" />
          <Logo src={logoFrance5} alt="France 5" />
          <Logo src={logoSudOuest} alt="Sud Ouest" />
          <Logo src={logoVoilesEtVoiliers} alt="Voiles et Voiliers" />
          <Logo src={logoYahoo} alt="Yahoo" />
          <Logo src={logoFigaro} alt="Figaro" />
        </div>
      </Wrapper>
    </Section>
  )
}

const Logo = ({ src, alt }) => {
  return (
    <img
      src={src}
      alt={alt}
      className="h-20 object-contain object-center"
      loading={"lazy"}
    />
  )
}
