import cx from "classnames"
import PropTypes from "prop-types"

export function MenuSeparator(props) {
  const { className, ...rest } = props
  const classNames = cx(
    "flex flex-row items-center justify-between border-t px-5 border-grey-light",
    className
  )

  return <div className={classNames} {...rest} />
}

MenuSeparator.propTypes = {
  className: PropTypes.string,
}
