import * as React from "react"

export const Image = React.forwardRef(function Image(
  { imageFile, size, alt, className },
  ref
) {
  if (!imageFile) {
    return null
  }
  const src = getImageSrc(imageFile, size)

  return (
    <img src={src} alt={alt} className={className} ref={ref} loading={"lazy"} />
  )
})

export const getImageSrc = (imageFile, size) => {
  if (size) {
    if (!imageFile.thumbs) {
      console.warn(
        `Trying to get the ${size} thumb on the following image, but it has no thumbs property`,
        imageFile
      )
      return imageFile.url
    }

    const thumb = imageFile.thumbs[size]

    if (!thumb) {
      console.warn(
        `Trying to get the ${size} thumb on the following image, but it's not available. Following thumbs are available: ${Object.keys(
          imageFile.thumbs
        ).join(", ")}`,
        imageFile
      )

      return imageFile.url
    }

    return thumb
  }

  return imageFile.url
}
