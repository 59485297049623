import * as React from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import { Text } from "components/Text"
import { RawLink, hrefPropType } from "components/Link"

export const MenuButton = React.forwardRef(function MenuButton(
  {
    label,
    color,
    href,
    illustration,
    className,
    endAdornment,
    disabled,
    onClick,
    ...props
  },
  ref
) {
  const classNames = cx(
    className,
    {
      "disabled:text-primary-light active:ring-primary-darker focus:ring-primary-light":
        color === "default",
      "disabled:text-light active:ring-primary-darker focus:ring-primary-lighter":
        color === "light",
      "bg-primary-lighter disabled:bg-transparent hover:bg-primary-light":
        label && color == "default",
      "bg-white disabled:bg-transparent hover:bg-primary-lighter":
        label && color == "light",
      "pr-4 rounded-4xl ": label,
      "pl-4": label && !illustration,
      "pl-1": label && illustration,
      "rounded-full disabled:text-light": !label,
      "active:ring-0": disabled,
      "active:ring-2": !disabled,
      "cursor-default": disabled,
    },
    "focus:outline-none inline-flex flex-row items-center justify-center space-x-2 focus:ring-4 text-primary-dark"
  )

  const Component = href ? RawLink : "button"

  function handleClick() {
    onClick?.()
  }

  return (
    <Component
      href={href}
      type={!href ? "button" : undefined}
      onClick={handleClick}
      className={classNames}
      disabled={disabled}
      {...props}
      ref={ref}
    >
      {illustration ? (
        <span
          className={cx(
            "border-2 rounded-full overflow-hidden shrink-0 text-center flex items-center justify-center",
            {
              "w-8 h-8": label,
              "w-10 h-10": !label,
              "text-white": color === "default" || label,
              "text-default": color === "light" && !label && !disabled,
              "text-light": color === "light" && !label && disabled,
              "border-primary-default bg-primary-default": !(
                disabled ||
                (!label && color === "light")
              ),
              "border-grey-white bg-grey-white": !label && color === "light",
              "border-primary-light bg-primary-light":
                (color === "default" || label) && disabled,
            }
          )}
        >
          {illustration}
        </span>
      ) : null}
      {label ? (
        <Text variant="subtitle1" tag="span" className="py-2">
          {label}
        </Text>
      ) : null}
      {endAdornment}
    </Component>
  )
})

MenuButton.defaultProps = {
  color: "default",
}

MenuButton.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(["default", "light"]),
  label: PropTypes.string,
  illustration: PropTypes.node,
  href: hrefPropType,
}
