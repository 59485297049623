import { useAsyncEffect } from "@react-hook/async"

export const DynamicIcon = ({ name, ...props }) => {
  const { value: Component } = useAsyncEffect(async () => {
    try {
      const module = await import(`@/components/Icon/${name}.js`)
      return module[name]
    } catch (_err) {
      console.error(`Icon ${name} cannot be found.`)

      return null
    }
  }, [name])

  if (Component) {
    return <Component {...props} />
  }

  return null
}
