import tailwindConfig from "utils/tailwindConfig"
import { useMediaQueries } from "@react-hook/media-query"
import mapValues from "lodash/mapValues"

const makeMediaQuery = (minWidth) => {
  return `screen and (min-width: ${minWidth})`
}

const mediaQueries = mapValues(tailwindConfig.theme.screens, makeMediaQuery)

export const useBreakpoints = () => {
  const { matches } = useMediaQueries(mediaQueries)

  return matches
}
