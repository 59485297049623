import PropTypes from "prop-types"
import { RouterProvider } from "components/Router"
import { TranslatorProvider } from "components/Translator"
import * as React from "react"
import { Alerter } from "components/Alert"
import { Header } from "components/Header"
import { Footer } from "components/Footer"
import { ConnectedUserProvider } from "components/ConnectedUserProvider"
import { ErrorBoundary } from "react-error-boundary"
import { ErrorFallback } from "components/ErrorFallback"
import { CartProvider } from "components/CartProvider"
import { ZendeskProvider } from "components/ZendeskWidget"

export const App = ({
  component: Component,
  locale,
  connectedUser,
  headerPrimaryNav,
  headerSecondaryNav,
  footerNav,
  flashMessages,
  children,
  ...props
}) => {
  return (
    <TranslatorProvider locale={locale}>
      <ZendeskProvider
        zendeskKey={process.env.ZENDESK_KEY}
        defaultVisible={!Component.hideHelpWidget}
      >
        <RouterProvider>
          <ConnectedUserProvider user={connectedUser}>
            <CartProvider>
              {headerPrimaryNav ? (
                <Header
                  user={connectedUser}
                  primaryNav={headerPrimaryNav}
                  secondaryNav={headerSecondaryNav}
                />
              ) : null}
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Component {...props} />
                {children}
                <Alerter />
                <FlashMessages messages={flashMessages} />
              </ErrorBoundary>
              {Component.hideFooter || !footerNav ? null : (
                <Footer footerNav={footerNav} />
              )}
            </CartProvider>
          </ConnectedUserProvider>
        </RouterProvider>
      </ZendeskProvider>
    </TranslatorProvider>
  )
}

App.propTypes = {
  component: PropTypes.elementType.isRequired,
  locale: PropTypes.string.isRequired,
  currentUser: PropTypes.object,
  headerPrimaryNav: PropTypes.object,
  headerSecondaryNav: PropTypes.object,
  flashMessages: PropTypes.object,
}

const FlashMessages = ({ messages }) => {
  React.useEffect(() => {
    if (!messages) {
      return null
    }

    Object.entries(messages).forEach((entry) => {
      const [type, messages] = entry

      messages.forEach((message) => {
        Alerter[type](message)
      })
    })
  }, [messages])

  return null
}

FlashMessages.propTypes = {
  messages: PropTypes.shape({
    notice: PropTypes.arrayOf(PropTypes.string),
    success: PropTypes.arrayOf(PropTypes.string),
    error: PropTypes.arrayOf(PropTypes.string),
    warning: PropTypes.arrayOf(PropTypes.string),
  }),
}
