import cx from "classnames"
import PropTypes from "prop-types"
import { forwardRef } from "react"
import { Text } from "components/Text"

export const NotificationCounter = forwardRef(function NotificationCounter(
  props,
  ref
) {
  const { count: countProps, children, className, showZero, ...rest } = props
  const classNames = cx("inline-flex relative", className)

  const count =
    countProps > MAX_SHOWN_VALUE ? `${MAX_SHOWN_VALUE}+` : String(countProps)

  const shouldRender = showZero || countProps > 0

  if (!shouldRender) {
    return children
  }

  return (
    <div className={classNames} {...rest} ref={ref}>
      {children}
      <div className="absolute top-1 left-1 -translate-x-1/2 -translate-y-1/2 rounded-full min-w-5 px-0.5 h-5 bg-error-default text-white pointer-events-none flex items-center justify-center">
        <Text variant="caption">{count}</Text>
      </div>
    </div>
  )
})

NotificationCounter.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  count: PropTypes.number.isRequired,
  showZero: PropTypes.bool,
}

NotificationCounter.defaultProps = {
  showZero: false,
}

const MAX_SHOWN_VALUE = 99
